







































































































import Vue from 'vue';
import { formatDateTime } from '@/utils/tools';
import { mapGetters } from 'vuex';
import SessionWrapper from '@/components/Viewer/SessionWrapper.vue';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  components: { SessionWrapper },
  data() {
    return {
      now: new Date(),
      form: {
        title: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      activeSession: 'viewer/getActiveSession',
      wordclouds: 'viewer/getSessionWordclouds',
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    wordcloud(): any {
      if (this.wordclouds && this.activeSession) {
        if ('activeWordcloud' in this.activeSession) {
          const [wordcloud] = this.wordclouds.filter(
            (item: any) => item.id === this.activeSession.activeWordcloud
          );
          return wordcloud;
        }
      }
      return {};
    },
    words(): any[] {
      if (this.wordcloud) {
        const list = [];
        for (const key in this.wordcloud) {
          if (key !== 'id') {
            list.push([key, this.wordcloud[key]]);
          }
        }
        return list;
      }
      return [];
    },
    activeWords() {
      let activeWordList: any[] = this.words;

      if (this.activeSession && this.activeSession.deactivatedWordsList) {
        activeWordList = activeWordList.filter(
          (n) => !this.activeSession.deactivatedWordsList.includes(n[0])
        );
      }

      return activeWordList;
    },
  },
  methods: {
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
    async submitForm() {
      const { title } = this.form;
      // Remove empty chars
      console.log(this.activeSession.pausedWordsList);
      const maskedTitle = title.replace(/\s/g, '');
      if (this.activeSession.activeWordcloud) {
        await this.$store.dispatch('viewer/submitWord', {
          word: maskedTitle.toLowerCase(),
          wordcloudId: this.activeSession.activeWordcloud,
          pausedWordsList: this.activeSession.pausedWordsList,
        });
        this.form.title = '';
      }
    },
  },
});
