var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SessionWrapper',{attrs:{"selectedMenuItem":"wordcloud","activeSession":_vm.activeSession || {}}},[_c('h1',{staticClass:"text-2xl font-medium lg:pb-2",style:({ color: _vm.theme.headlineColor })},[_vm._v(" "+_vm._s((_vm.activeSession && _vm.activeSession.labels && _vm.activeSession.labels.wordcloud) || _vm.$t('views.wordcloud'))+" ")]),(_vm.activeSession && _vm.activeSession.isWordcloudActive)?[(_vm.activeSession.wordcloudTopic)?_c('p',{staticClass:"mt-3 text-md pb-5",style:({ color: _vm.theme.headlineColor })},[_vm._v(" "+_vm._s(_vm.activeSession.wordcloudTopic)+" ")]):_vm._e()]:_vm._e(),_c('div',{staticClass:"mt-3 shadow-reaction rounded-card max-w-lg lg:max-w-auto mx-auto lg:mx-0"},[(_vm.activeSession && _vm.activeSession.isWordcloudActive)?_c('div',{staticClass:"flex justify-center items-center bg-white p-5 rounded-card"},[_c('vue-word-cloud',{staticStyle:{"height":"320px","width":"100%"},attrs:{"words":_vm.activeWords,"spacing":0.75,"animation-overlap":2,"color":function (ref) {
            var weight = ref[1];

            if (_vm.activeSession) {
              return weight > 10
                ? _vm.theme.primaryColor
                : weight > 5
                ? _vm.theme.secondaryColor
                : _vm.theme.textColor;
            }
          },"font-family":"Red Hat Display"}})],1):_vm._e()]),(
      _vm.activeSession &&
      _vm.activeSession.isWordcloudActive &&
      !_vm.activeSession.isWordcloudPaused
    )?_c('form',{staticClass:"mt-2 max-w-lg lg:max-w-auto mx-auto lg:mx-0 mb-10 lg:pb-0",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"block py-3 px-5 w-full rounded-card border-none shadow-reaction placeholder-light-gray focus:ring-dark-gray focus:border-dark-gray",style:({ color: _vm.theme.headlineColor }),attrs:{"id":"description","name":"description","type":"text","placeholder":_vm.activeSession.wordcloudInputPlaceholder
          ? _vm.activeSession.wordcloudInputPlaceholder
          : _vm.$t('views.viewer.sessionViews.wordcloudPlaceholderYourWord')},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "title", $event.target.value)}}}),_c('button',{staticClass:"mt-5 px-12 py-3 text-md rounded-full text-white mx-auto disabled:opacity-50 disabled:cursor-not-allowed",style:({
        color: _vm.theme.buttonTextColor,
        'background-color': _vm.theme.buttonColor,
      }),attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('views.btnSend'))+" ")])]):_c('div',{staticClass:"mt-10 flex justify-center lg:justify-start lg:mt-3 items-center"},[(_vm.activeSession && _vm.activeSession.isWordcloudPaused)?_c('p',{staticClass:"text-md",style:({ color: _vm.theme.textColor })},[_vm._v(" "+_vm._s(_vm.$t('views.viewer.sessionViews.wordcloudPaused'))+" ")]):_c('p',{staticClass:"text-md",style:({ color: _vm.theme.textColor })},[_vm._v(" "+_vm._s(_vm.$t('views.viewer.sessionViews.wordcloudNotActive'))+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }